export default class ProgressBar {
  constructor(config) {
    const progressBarContainer = document.getElementById(config.progressBarContainerId);
    const progressBar = document.getElementById(config.progressBarId);

    if (progressBarContainer === undefined) {
      console.error("Aucun élément html avec l'ID " + progressBarContainer + " n'existe.");
    }
    if (progressBar === undefined) {
      console.error("Aucun élément html avec l'ID " + progressBar + " n'existe.");
    }

    this.percent = 0;
    this.progressBarContainer = progressBarContainer;
    this.progressBar = progressBar;
  }

  reset = () => {
    this.percent = 0;
    this.progressBar.style.width = "0%";
    this.progressBarContainer.classList.remove("hidden");
  }

  animateProgress(timeout = 20) {
    const progressInterval = setInterval(() => {
      this.percent += 1;
      this.progressBar.style.width = this.percent + "%";
      this.#cleanIntervalAndHideBar(progressInterval)
    }, timeout);
  }

  #cleanIntervalAndHideBar(interval) {
    if (this.percent < 100) return;

    clearInterval(interval);
    setTimeout(() => {
      this.progressBarContainer.classList.add("hidden");
    }, 150)
  }
}
